/* Mobile-specific fixes */

/* Use custom viewport height to handle Safari's bottom toolbar */
body {
  overscroll-behavior: none; /* Prevent pull-to-refresh and bounce effects */
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Fallback for browsers that don't support custom properties */
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  position: relative; /* Changed from fixed to allow scrolling */
  overflow: visible; /* Changed from hidden to allow scrolling */
}

/* Mobile bottom navigation - ensure it stays fixed and visible above Safari's toolbar */
.mobile-bottom-nav {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 9999 !important; /* Use very high z-index */
  box-shadow: 0px -4px 10px rgba(0,0,0,0.2) !important;
  height: auto !important;
  min-height: 64px !important;
}

/* Ensure icons and labels in bottom nav are properly positioned */
.mobile-bottom-nav .MuiBottomNavigationAction-root {
  padding-top: 8px !important;
  padding-bottom: calc(env(safe-area-inset-bottom, 8px) + 8px) !important;
  height: auto !important;
  min-height: 64px !important;
}

/* Add specific iOS fixes */
@supports (-webkit-touch-callout: none) {
  html, body {
    /* Use the custom vh variable */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    overflow: visible;
    width: 100%;
  }
  
  /* Make scrollable areas scrollable */
  .mobile-container {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    height: auto !important;
    /* min-height: calc(100vh - env(safe-area-inset-bottom, 0px)) !important; */
    /* Add padding for bottom navigation */
    /* padding-bottom: calc(64px + env(safe-area-inset-bottom, 16px)) !important; */
    /* Ensure content starts below status bar */
    /* padding-top: env(safe-area-inset-top, 0px) !important; */
    position: relative !important;
    z-index: 1 !important;
  }
  

}

/* Fix for the Explore card swipe area so buttons are always visible */
.swipe-card {
  /* Position centrally and fix scaling issues */
  position: relative !important;
  width: 100% !important;
  max-width: 600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  /* Add reasonable bottom margin */
  /* margin-bottom: calc(env(safe-area-inset-bottom, 16px) + 20px) !important; */
  /* Ensure the card has sufficient height */
  min-height: 350px !important; 
  /* Make all content inside visible */
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

/* Existing CSS rules */ 

/* Intro.js custom styles - UPDATED TO MATCH HATOI BRAND */
.hatoi-intro-tooltip.introjs-tooltip {
  background-color: #1A1A24;
  color: white;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  max-width: 85%;
  border: 1px solid rgba(92, 255, 231, 0.3);
  padding: 16px;
  backdrop-filter: blur(5px);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.hatoi-intro-tooltip .introjs-tooltiptext {
  color: white;
  line-height: 1.7;
  font-size: 1rem;
  margin-bottom: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.hatoi-intro-tooltip .introjs-tooltip-title {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.hatoi-intro-tooltip .introjs-tooltipbuttons {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

/* Back button overrides - push to left edge */
.hatoi-intro-tooltip .introjs-button.introjs-prevbutton {
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(92, 255, 231, 0.3);
  color: #ffffff;
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  text-align: center;
}

/* Next and Done button overrides */
.hatoi-intro-tooltip .introjs-button.introjs-nextbutton,
.hatoi-intro-tooltip .introjs-button.introjs-donebutton {
  background-color: #5CFFE7;
  color: #1A1A24;
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  text-align: center;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.hatoi-intro-tooltip .introjs-button.introjs-nextbutton:hover,
.hatoi-intro-tooltip .introjs-button.introjs-donebutton:hover {
  background-color: #4DEBD4;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Fix the skip button */
.hatoi-intro-tooltip .introjs-skipbutton {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.4rem;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease;
}

/* Make sure mobile styling is consistent */
@media (max-width: 600px) {
  .hatoi-intro-tooltip .introjs-tooltipbuttons {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .hatoi-intro-tooltip .introjs-button {
    padding: 8px 16px;
    font-size: 0.8rem;
    margin: 4px;
  }
  
  .hatoi-intro-tooltip .introjs-button.introjs-nextbutton,
  .hatoi-intro-tooltip .introjs-button.introjs-donebutton,
  .hatoi-intro-tooltip .introjs-button.introjs-prevbutton {
    min-width: unset;
    flex-grow: 1;
  }
}

/* Progress bar styling */
.hatoi-intro-tooltip .introjs-progress {
  background-color: rgba(255, 255, 255, 0.1);
  height: 6px;
  border-radius: 3px;
  margin-bottom: 14px;
}

.hatoi-intro-tooltip .introjs-progressbar {
  background-color: #5CFFE7; /* Brand turquoise color for progress */
  border-radius: 3px;
  height: 6px;
}

/* Bullets styling */
.hatoi-intro-tooltip .introjs-bullets {
  margin-bottom: 14px;
}

.hatoi-intro-tooltip .introjs-bullets ul {
  display: flex;
  justify-content: center;
}

.hatoi-intro-tooltip .introjs-bullets ul li a {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.hatoi-intro-tooltip .introjs-bullets ul li a.active {
  background: #5CFFE7; /* Brand turquoise for active bullet */
  transform: scale(1.2);
}

.hatoi-intro-tooltip .introjs-bullets ul li a:hover {
  background: rgba(255, 255, 255, 0.6);
}

/* Arrow styling */
.hatoi-intro-tooltip .introjs-arrow {
  border-color: transparent transparent #1A1A24 transparent !important;
}

.hatoi-intro-tooltip .introjs-arrow.top-middle,
.hatoi-intro-tooltip .introjs-arrow.top {
  border-color: #1A1A24 transparent transparent !important;
}

.hatoi-intro-tooltip .introjs-arrow.right {
  border-color: transparent #1A1A24 transparent transparent !important;
}

.hatoi-intro-tooltip .introjs-arrow.left {
  border-color: transparent transparent transparent #1A1A24 !important;
}

/* Highlight styling */
.hatoi-intro-highlight {
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.75) !important;
  border: 2px solid #5CFFE7 !important;
  border-radius: 4px !important;
}

/* Make sure intro.js elements are visible on mobile */
@media (max-width: 768px) {
  .hatoi-intro-tooltip.introjs-tooltip {
    max-width: 85vw;
    min-width: 250px;
  }
  
  .introjs-helperLayer {
    z-index: 9998 !important;
  }
  
  .introjs-overlay {
    z-index: 9997 !important;
  }
  
  .introjs-tooltipReferenceLayer {
    z-index: 9999 !important;
  }
  
  /* Fix for tooltips at the bottom of the screen */
  .introjs-tooltip[class*="bottom"] {
    margin-bottom: 80px !important;
  }
}

/* Fix Intro.js tooltip when bottom navigation is shown */
.introjs-tooltip[class*="bottom"] {
  margin-bottom: 80px !important;
}

/* Ensure tooltip buttons are styled properly on mobile */
.hatoi-intro-tooltip .introjs-button {
  text-shadow: none !important;
  min-width: 60px;
  text-align: center;
}

/* Override the main tooltip styles to ensure visibility */
.introjs-tooltip {
  background-color: #1A1A24 !important;
  color: #fff !important;
}

/* Make sure intro.js modal appears above everything else */
.introjs-overlay, .introjs-helperLayer, .introjs-tooltipReferenceLayer {
  position: fixed !important;
}

/* Custom introjs styling */
.hatoi-intro-tooltip {
  background-color: #1A1A24;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  max-width: 90vw;
}

/* Specific styling for the Submit button tooltip */
.submit-tooltip-centered.introjs-tooltip,
.submit-tooltip-centered.introjs-tooltip.introjs-top-left-aligned,
.submit-tooltip-centered.introjs-tooltip.introjs-top-right-aligned,
.submit-tooltip-centered.introjs-tooltip.introjs-bottom-left-aligned,
.submit-tooltip-centered.introjs-tooltip.introjs-bottom-right-aligned {
  margin-top: 15px !important;
  transform: translateX(-50%) !important;
  left: 50% !important;
  position: fixed !important;
  bottom: 84px !important; /* Fixed position from bottom for mobile */
}

/* Force override any inline styles */
.submit-tooltip-centered.introjs-tooltip[style*="left"],
.submit-tooltip-centered.introjs-tooltip.introjs-top-left-aligned[style*="left"],
.submit-tooltip-centered.introjs-tooltip.introjs-bottom-left-aligned[style*="left"] {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

/* Override alignment for arrow */
.submit-tooltip-centered .introjs-arrow,
.submit-tooltip-centered .introjs-arrow.bottom,
.submit-tooltip-centered .introjs-arrow.top,
.submit-tooltip-centered .introjs-arrow.left,
.submit-tooltip-centered .introjs-arrow.right {
  display: block !important;
  border-bottom-color: #1A1A24 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: -10px !important;
  bottom: auto !important;
  right: auto !important;
}

/* Existing submit tooltip styles - keeping for backwards compatibility */
.submit-tooltip.introjs-tooltip {
  margin-bottom: 15px !important;
}

.submit-tooltip .introjs-tooltip-arrow {
  border-top-color: #1A1A24 !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.hatoi-intro-tooltip .introjs-tooltip-title {
  color: #ffffff;
  font-weight: bold;
}

/* Basic button styles */
.hatoi-intro-tooltip .introjs-button {
  background-color: #5CFFE7;
  color: #1A1A24;
  border: none;
  text-shadow: none;
  border-radius: 8px;
  padding: 10px 24px;
  margin: 5px;
  transition: all 0.2s ease;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: none;
}

.hatoi-intro-tooltip .introjs-button:hover {
  background-color: #4DEBD4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-1px);
}

.hatoi-intro-tooltip .introjs-button.introjs-skipbutton:hover {
  background-color: transparent;
  color: #ffffff;
  box-shadow: none;
}

.hatoi-intro-tooltip .introjs-button.introjs-prevbutton:hover {
  background-color: rgba(92, 255, 231, 0.2);
}

/* Responsive adjustments for mobile */
@media (max-width: 600px) {
  .hatoi-intro-tooltip {
    max-width: 85vw;
  }
  
  .hatoi-intro-tooltip .introjs-button {
    padding: 5px 10px;
    font-size: 0.85rem;
  }
}

/* Direct fix for the Submit tooltip in the DOM as shown in the HTML */
.introjs-tooltip.hatoi-intro-tooltip.introjs-top-left-aligned {
  left: 50% !important;
  transform: translateX(-50%) !important;
  position: fixed !important;
  bottom: 84px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Target the exact class combination from the HTML */
div.introjs-tooltip.hatoi-intro-tooltip.introjs-top-left-aligned[style*="left"] {
  left: 50% !important;
  transform: translateX(-50%) !important;
  position: fixed !important;
  bottom: 84px !important;
}

/* Ensure the arrow is correctly positioned */
div.introjs-tooltip.hatoi-intro-tooltip.introjs-top-left-aligned .introjs-arrow.bottom {
  left: 50% !important;
  transform: translateX(-50%) !important;
  border-bottom-color: #1A1A24 !important;
}

/* Target inline styles specifically */
.introjs-tooltip[style*="left: 15px"] {
  left: 50% !important;
  transform: translateX(-50%) !important;
} 